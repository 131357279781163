import styled from "styled-components";
import { StyledInputList } from "components/atoms/form/input/input.styled";
import { SubmitButton } from "components/atoms/form/submit/submit.component";
import { Typography } from "components/atoms/typography/typography.component";
import { StylesToBase } from "components/atoms/typography/typography.styled";

export const StyledImageGoogle = styled.div`
    margin: 0 auto 4px;
    width: 115px;
    height: 67px;
    position: relative;
`;

export const StyledTextGoogle = styled(Typography)`
    margin-bottom: 30px;
`;

export const StyledInputContainer = styled(StyledInputList)`
    margin-bottom: 30px;
`;

export const StyledButtonForm = styled(SubmitButton)`
    margin-bottom: 40px;
`;

export const StyledButtonBack = styled.button`
    ${StylesToBase};
    color: ${({ theme }) => theme.green?.[800]};
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
`;
