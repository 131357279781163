import styled from "styled-components";
import { spacing } from "helpers/conversion-style";
import { StylesToButton } from "components/atoms/typography/typography.styled";

export const StyledSubmit = styled.input`
    ${StylesToButton};
    width: 100%;
    border: none;
    padding: ${spacing(12)};
    color: ${({theme}) => theme.green?.[1400]};
    background-color: ${({theme}) => theme.green?.[800]};
    border-radius: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 56px;
    transition: 0.3s;
    &:disabled {
        pointer-events: none;
        background-color: ${({theme}) => theme.gray?.[100]};
        color: ${({theme}) => theme.text?.default};
    }
`;
