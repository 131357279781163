import React, { useState, useMemo } from "react";
import { signIn } from "next-auth/react";
import { usePostHog } from "posthog-js/react";
import { StatusAuthTypes } from "helpers/auth.types";
import { useRouter } from "next/router";
import { useTranslation, TFunction } from "next-i18next";
import { SubmitButton } from "components/atoms/form/submit/submit.component";
import { Input } from "components/atoms/form/input/input.component";
import { CheckboxElement } from "components/atoms/form/checkbox/checkbox.component";
import { FormError } from "components/atoms/form/error/error.component";
import * as yup from "yup";
import { deleteCookie } from "helpers/delete-cookie";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validators } from "lib/validators/validators";
import { setNotificationsWTN } from "helpers/wtn/notifications";
import { useCheckDevice } from "hooks/use-check-device/use-check-device";
import { setIdentifyUserId } from "helpers/wtn/identify";
import { trackingLogin } from "helpers/wtn/firebaseEntrance";
import { dataLayerLogin } from "helpers/dataLayer/entrance";
import {
    StyledLoginInput,
    StyledPasswordWrapper,
    StyledPasswordLink,
    StyledLoginForm,
} from "./login-form.styled";
import { logRequest, logResponse, logError } from "helpers/loggerData";

export interface IProps {
    onTwoFa: (
        type: boolean,
        email: string,
        password: string,
        rememberMe: boolean,
    ) => void;
    loggerData: { [key: string]: string };
}

export const LoginForm: React.FC<IProps> = ({ onTwoFa, loggerData }) => {
    const [__] = useTranslation();
    const schema = useSchema(__);
    const { mobile, device } = useCheckDevice();
    const posthog = usePostHog();

    const { defaultLocale, locale } = useRouter() || {};
    const callbackUrl = (defaultLocale && locale && defaultLocale !== locale) ? `/${locale}` : "/";

    const [formValue, setFormValue] = useState({
        passwordRemember: true,
        error: false,
    });

    const { handleSubmit, formState, register } = useForm({
        mode: "onTouched",
        resolver: yupResolver(schema),
        reValidateMode: "onBlur",
    });

    const onSubmit = handleSubmit(async ({ email, password }) => {
        deleteCookie("amega");

        const logData = {
            desc: "Login",
            method: "POST",
            url: "/client-api/login",
            email,
            ...(loggerData && loggerData),
        };
        const { url, method } = logData;

        try {
            const body = {
                email,
                password,
                rememberMe: formValue.passwordRemember,
            };

            logRequest(logData, body);

            const response = await fetch(
                url, {
                method,
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(body),
            });

            logResponse(logData, response);

            const { ok: isResponseOK } = response || {};

            if (isResponseOK) {
                const data = await response.json();

                if (data?.login) {
                    return onTwoFa(
                        true,
                        email,
                        password,
                        formValue.passwordRemember,
                    );
                } else if (data.accessToken) {
                    if (mobile) {
                        setNotificationsWTN(data.client.id, device);
                    }
                    posthog?.identify(`${data.client.id}`,
                        {email: data.client.email}
                    );
                    posthog?.capture("sign_in");

                    setIdentifyUserId(data.client.id);
                    trackingLogin(data.client.isVerified);
                    dataLayerLogin(
                        data.client.id,
                        data.client.isVerified
                    );

                    await signIn("login", {
                        exp: data.exp,
                        expDate: data.expDate,
                        accessToken: data.accessToken,
                        id: data.client.id,
                        isVerified: data.client.isVerified,
                        firstDepositId: data.client.firstDepositId,
                        registrationDate: data.client.registrationDate,
                        emailVerified: data.client.emailVerified,
                        status: StatusAuthTypes.LOGIN,
                        callbackUrl,
                    });
                }
            } else {
                return setFormValue((prev) => ({
                    ...prev,
                    error: true,
                }));
            }
        } catch (error: unknown) {
            console.error(error);
            logError(logData, error);
        }
    });

    return (
        <>
            {
                formValue.error &&
                <FormError
                    text={__("auth.login.incorrect-email")}
                    onClick={() =>
                        setFormValue((prev) => ({
                            ...prev,
                            error: false,
                        }))
                    }
                />
            }
            <StyledLoginForm onSubmit={onSubmit}>
                <StyledLoginInput>
                    <Input
                        labelText={__("auth.login.email")}
                        type="text"
                        autoComplete="username"
                        autoCorrect="off"
                        {...register("email")}
                        error={formState.errors.email?.message}
                    />
                    <Input
                        labelText={__("auth.login.password")}
                        type="password"
                        autoComplete="current-password"
                        {...register("password")}
                        error={formState.errors.password?.message}
                    />
                </StyledLoginInput>
                <StyledPasswordWrapper>
                    <CheckboxElement
                        id="remember"
                        text={__("auth.login.remember-me")}
                        onChange={() => {
                            setFormValue((prev) => ({
                                ...prev,
                                passwordRemember: !prev.passwordRemember,
                            }));
                        }}
                        checked={formValue.passwordRemember}
                        fullClick
                    />
                    <StyledPasswordLink href="/forgot-password" >{__("auth.login.forgot-password")}</StyledPasswordLink>
                </StyledPasswordWrapper>
                <SubmitButton value={__("auth.login.signin")} />
            </StyledLoginForm>
        </>
    );
};

function useSchema(__: TFunction) {
    return useMemo(
        () =>
            yup.object().shape({
                email: validators(__).email,
                password: validators(__).default,
            }),
        [__],
    );
}
