import _ from "lodash";

const __ = _.identity;

export class I18nKeys {
    public static get FIELD_LETTERS_NUMBERS_ONLY() {
        return __("errors.only-letters-numbers");
    }

    public static get FIELD_REQUIRED() {
        return __("errors.field-required");
    }

    public static get FIELD_LATIN() {
        return __("errors.field-latin");
    }

    public static get FIELD_MIN_LENGTH() {
        return __("errors.min-length");
    }

    public static get FIELD_MAX_LENGTH() {
        return __("errors.max-length");
    }

    public static get FIELD_REG_LATIN() {
        return __("errors.reg-latin");
    }

    public static get CONTAINS_INVALID_CHARACTERS() {
        return __("errors.contains-invalid-characters");
    }

    public static get CONTAINS_STRONG_PASSWORD() {
        return __("errors.contains-strong-password");
    }

    public static get CONFIRM_PASSWORD() {
        return __("errors.confirm-password");
    }
}
