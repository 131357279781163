import styled from "styled-components";
import { StylesToSmall } from "components/atoms/typography/typography.styled";
import { StyledInputList } from "components/atoms/form/input/input.styled";
import { CustomLink } from "components/atoms/custom-link/custom-link.component";

export const StyledPasswordWrapper = styled.div`
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledLoginForm = styled.form`
    margin-bottom: 32px;
`;

export const StyledLoginInput = styled(StyledInputList)`
    margin-bottom: 12px;
`;

export const StyledPasswordLink = styled(CustomLink)`
    ${StylesToSmall};
    color: ${({theme}) => theme.text?.muted};
`;
