import React, { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { COOKIES_NAMES, COOKIES_DELETE_OPTIONS } from "lib/configuration";
import { LoginForm } from "components/organisms/form/login-form/login-form.component";
import { TwoFaModule } from "./twoFa/twoFa.component";
import { GoogleSocial } from "components/molecules/google-social/google-social.component";
import { FacebookSocial } from "components/molecules/facebook.social/facebook.social.component";
import { useCookies } from "react-cookie";
import { useModals } from "components/providers/modals/modals.provider";
import {
    StyledLogin,
    StyledTitle,
    StyledText,
    StyledLinkSignUp,
    StyledFormArea,
    StyledLoginContent,
    StyledTextSignIn,
    StyledSocialList,
} from "./login.styled";

export interface ILogin {
    email: string;
    password: string;
    rememberMe: boolean;
}

export interface LoginProps {
    loggerData: { [key: string]: string };
}

export const LoginTemplate: React.FC<LoginProps> = ({ loggerData }) => {
    const [__] = useTranslation();
    const [ twoFa, setTwoFa ] = useState<boolean>(false);
    const [ cookies, setCookie ] = useCookies([COOKIES_NAMES.socialLoginError]);
    const { openConnectModal } = useModals();
    const [ dataLogin, setDataLogin ] = useState<ILogin>({
        email: "",
        password: "",
        rememberMe: false,
    });

    const onTwoFa = (
        type: boolean,
        email: string,
        password: string,
        rememberMe: boolean,
    ) => {
        setTwoFa(type);
        setDataLogin(() => ({
            email,
            password,
            rememberMe,
        }));
    };

    useEffect(() => {
        if(cookies[COOKIES_NAMES.socialLoginError]) {
            setTimeout(() => {
                openConnectModal();
                setCookie(COOKIES_NAMES.socialLoginError, "", COOKIES_DELETE_OPTIONS);
            }, 500);
        }
    }, [cookies]);

    return (
        <StyledLogin>
            <StyledLoginContent>
                <StyledTitle variant={TypographyTypes.H1}>{__("auth.login.page-title")}</StyledTitle>
                {twoFa ?
                    <TwoFaModule
                        onBack={(type: boolean) => setTwoFa(type)}
                        rememberMe={dataLogin.rememberMe}
                    />
                :
                    <>
                        <StyledFormArea>
                            <LoginForm loggerData={loggerData} onTwoFa={onTwoFa}/>
                        </StyledFormArea>
                        <div>
                            <StyledTextSignIn variant={TypographyTypes.BASE}>{__("auth.login.sign-in-social")}</StyledTextSignIn>
                            <StyledSocialList>
                                <GoogleSocial />
                                <FacebookSocial />
                            </StyledSocialList>
                        </div>
                        <StyledText variant={TypographyTypes.BASE}>
                            {__("auth.login.not-registered")}
                            <StyledLinkSignUp href="/register">{__("auth.login.signup")}</StyledLinkSignUp>
                        </StyledText>
                    </>
                }
            </StyledLoginContent>
        </StyledLogin>
    );
};
