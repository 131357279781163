import React, { InputHTMLAttributes } from "react";
import { StyledSubmit } from "./submit.styled";

export interface ISubmit extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    value: string;
}

export const SubmitButton: React.FC<ISubmit> = ({className, value, ...props}) => {
    return (
        <StyledSubmit
            value={value}
            className={className}
            {...props}
            type="submit"
            data-testid="test-submit"
        />
    );
};
