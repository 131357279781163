import styled from "styled-components";
import { spacing } from "helpers/conversion-style";
import { Icon } from "components/atoms/icon/icon.component";

export const StyledFormError = styled.div`
    background-color: ${({theme}) => theme.red?.[300]};
    color: ${({theme}) => theme.red?.[1300]};
    border: 1px solid ${({theme}) => theme.red?.[800]};
    border-radius: 16px;
    padding: ${spacing(16)} ${spacing(8)} ${spacing(16)} ${spacing(12)};
    margin: 16px 0 30px;
    display: grid;
    grid-template-columns: 1fr 24px;
    gap: 12px;
    text-align: left;
    align-items: center;
`;

export const StyledErrorClose = styled.button`
    background: none;
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
    cursor: pointer;
`;

export const StyledErrorIcon = styled(Icon)`
    width: 24px;
    min-width: 24px;
    height: 24px;
    stroke: ${({theme}) => theme.red?.[1300]};
`;
