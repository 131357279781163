import React from "react";
import { ButtonTypes, ButtonIcon } from "components/atoms/button-link/button-link.types";
import { ButtonLink } from "components/atoms/button-link/button-link.component";

export const FacebookSocial: React.FC = () => {

    const handleLoginCode = () => {
        const appId = process.env.FACEBOOK_WEB_CLIENT_ID;
        const redirectUri = `${window.location.origin}/login/check-facebook`;
        const scope = "email,public_profile";

        const fbLoginUrl = `https://www.facebook.com/v12.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&scope=${scope}`;

        window.location.href = fbLoginUrl;
    };

    return (
        <>
            <ButtonLink
                variant={ButtonTypes.SOCIAL}
                onClick={() => handleLoginCode()}
                icon={ButtonIcon.FACEBOOK}
            />
        </>
    );
};
