import { logRequest, logResponse, logError } from "helpers/loggerData";
import { IConfig } from "types/next-auth";

export interface IAuthParams {
    parameters: any;
    redirectUrl: string;
    rememberMe?: boolean;
}

export enum ISocialStatus {
    SUCCESS,
    ERROR,
}

export interface ISocialResponse {
    status: ISocialStatus;
    user?: IConfig;
}

export const socialAuth = async (
    service: "google" | "facebook",
    body: IAuthParams,
): Promise<ISocialResponse | undefined> => {

    const logData = {
        desc: `Social login auth ${service}`,
        method: "POST",
        url: `${process.env.NEXT_PUBLIC_API}/client-api/oauth/auth/${service}`,
    };
    const { url, method } = logData;

    try {
        logRequest(logData, body);

        const response = await fetch(url,
            {
                method,
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            },
        );

        const { ok: responseOk } = response;

        logResponse(logData, response);

        const data = await response.json();

        if ( responseOk ) {
            return {
                status: ISocialStatus.SUCCESS,
                user: data,
            };
        } else {
            return {
                status: ISocialStatus.ERROR,
            };
        }

    } catch (error: unknown) {
        console.error(error);
        logError(logData, error);
    }
};
