export const trackingLogin = ( userVerified: boolean ) => {
    if (typeof window === "undefined") { return; }

    const { Analytics: FirebaseAnalytics } = window.WTN.Firebase;

    FirebaseAnalytics.logEvent({
        eventName: "sign_in",
    });

    FirebaseAnalytics.setUserProperty({
        key: "user_verified",
        value: userVerified,
    });
};

export const trackingRegister = () => {
    if (typeof window === "undefined") { return; }

    const { Analytics: FirebaseAnalytics } = window.WTN.Firebase;

    FirebaseAnalytics.logEvent({
        eventName: "sign_up",
    });
};
