import styled from "styled-components";
import { Section } from "components/molecules/section/section.component";
import { CustomLink } from "components/atoms/custom-link/custom-link.component";
import { Typography } from "components/atoms/typography/typography.component";
import { media } from "lib/styled-components/variables";
import { spacing } from "helpers/conversion-style";

export const StyledLogin = styled(Section)`
    text-align: center;
`;

export const StyledLoginContent = styled.div`
    ${media.sm`
        display: grid;
        width: 100%;
        grid-template-areas:
            "title"
            "form"
            "button"
            "text";
    `};
`;

export const StyledTitle = styled(Typography)`
    margin-bottom: 32px;
    grid-area: title;
`;

export const StyledText = styled(Typography)`
    color: ${({theme}) => theme.text?.muted};
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;
    grid-area: text;
`;

export const StyledLinkSignUp = styled(CustomLink)`
    color: ${({theme}) => theme.green?.[800]};
`;

export const StyledFormArea = styled.div`
    grid-area: form;
`;

export const StyledTextSignIn = styled(Typography)`
    color: ${({theme}) => theme.text?.muted};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: ${spacing(12)};
    margin-bottom: ${spacing(16)};
    &:before,
    &:after {
        content: "";
        min-width: 86px;
        background-color: ${({theme}) => theme.text?.muted};
        height: 1px;
    }
`;

export const StyledSocialList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: ${spacing(20)};
    margin-bottom: ${spacing(44)};
`;
