export const dataLayerLogin = (
    userId: string | number,
    verified: boolean,
) => {
    if (typeof window === "undefined") { return; }

    window.dataLayer.push({
        event: "sign_in",
        user_properties: {
            user_id: userId,
            user_verified: verified,
        },
    });
};

export const dataLayerRegister = (
    userId: string | number,
    experiment_name?: string,
    experiment_group?: string | boolean | undefined,
) => {
    if (typeof window === "undefined") { return; }

    let params = {};
    if(experiment_name && experiment_group) {
        params = {
            experiment_name,
            experiment_group: experiment_group || "",
        };
    }

    window.dataLayer.push({
        event: "sign_up",
        event_params: params,
        user_properties: {
            user_id: userId,
        },
    });
};
