import React from "react";
import { Typography } from "components/atoms/typography/typography.component";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import { IconTypes } from "components/atoms/icon/icon.types";
import {
    StyledFormError,
    StyledErrorClose,
    StyledErrorIcon,
} from "./error.styled";

export interface IProps {
    text: string;
    className?: string;
    onClick: () => void;
}

export const FormError: React.FC<IProps> = ({ text, className, onClick }) => {
    return(
        <StyledFormError className={className}>
            <Typography variant={TypographyTypes.BASE}>{text}</Typography>
            <StyledErrorClose onClick={onClick} data-testid="close-button">
                <StyledErrorIcon icon={IconTypes.CLOSE}/>
            </StyledErrorClose>
        </StyledFormError>
    );
};
