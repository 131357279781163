import { Typography } from "components/atoms/typography/typography.component";
import { spacing } from "helpers/conversion-style";
import styled from "styled-components";

export const StyledSection = styled.section`
    padding: ${spacing(16)} 0;
`;

export const StyledTitle = styled(Typography)`
    color: ${({theme}) => theme.text?.muted};
    margin-bottom: 16px;
`;
