import _ from "lodash";
import * as yup from "yup";
import { I18nKeys } from "./i18n-keys.constant";
import { RegExpUtils } from "./regexp-validators";

export const validators = (__: import("i18next").TFunction = _.identity) => {
    return {
        onlyNumbersLetters: yup
            .string()
            .required(__(I18nKeys.FIELD_REQUIRED))
            .matches(
                    /^[a-zA-Z0-9]+$/,
                    __(I18nKeys.FIELD_LETTERS_NUMBERS_ONLY)
            )
            .trim(),
        email: yup
            .string()
            .required(__(I18nKeys.FIELD_REQUIRED))
            .matches(
                RegExpUtils.email,
                __(I18nKeys.CONTAINS_INVALID_CHARACTERS),
            )
            .trim()
            .lowercase(),
        default: yup
            .string()
            .required(__(I18nKeys.FIELD_REQUIRED)),
        latin: yup
            .string()
            .required(__(I18nKeys.FIELD_REQUIRED))
            .min(2, __(I18nKeys.FIELD_MIN_LENGTH))
            .max(50, __(I18nKeys.FIELD_MAX_LENGTH))
            .matches(
                RegExpUtils.latin,
                __(I18nKeys.FIELD_REG_LATIN),
            ),
        password: yup
            .string()
            .required(__(I18nKeys.FIELD_REQUIRED))
            .min(8, __(I18nKeys.CONTAINS_STRONG_PASSWORD))
            .max(32, __(I18nKeys.CONTAINS_STRONG_PASSWORD))
            .matches(
                RegExpUtils.containsUppercaseLetter,
                __(I18nKeys.CONTAINS_STRONG_PASSWORD),
            )
            .matches(
                RegExpUtils.containsLowercaseLetter,
                __(I18nKeys.CONTAINS_STRONG_PASSWORD),
            )
            .matches(
                RegExpUtils.containsDigit,
                __(I18nKeys.CONTAINS_STRONG_PASSWORD),
            ),
        confirmPassword: yup
            .string()
            .required(__(I18nKeys.FIELD_REQUIRED))
            .min(8, __(I18nKeys.CONTAINS_STRONG_PASSWORD))
            .max(32, __(I18nKeys.CONTAINS_STRONG_PASSWORD))
            .matches(
                RegExpUtils.containsUppercaseLetter,
                __(I18nKeys.CONTAINS_STRONG_PASSWORD),
            )
            .matches(
                RegExpUtils.containsLowercaseLetter,
                __(I18nKeys.CONTAINS_STRONG_PASSWORD),
            )
            .matches(
                RegExpUtils.containsDigit,
                __(I18nKeys.CONTAINS_STRONG_PASSWORD),
            )
            .oneOf(
                [yup.ref("password")],
                __(I18nKeys.CONFIRM_PASSWORD),
            ),
    };
};
