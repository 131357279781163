import React from "react";
import { StyledContainer } from "styles/basic.styled";
import { TypographyTypes } from "components/atoms/typography/typography.types";
import {
    StyledSection,
    StyledTitle,
} from "./section.styled";

export interface IProps extends React.PropsWithChildren {
    className?: string;
    id?: string;
    title?: string;
}

export const Section: React.FC<IProps> = ({ children, className, id, title }) => {
    return (
        <StyledSection className={className} id={id}>
            <StyledContainer>
                {title && <StyledTitle variant={TypographyTypes.H3}>{title}</StyledTitle>}
                {children}
            </StyledContainer>
        </StyledSection>
    );
};
