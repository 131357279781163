type CookieObject = { [key: string]: string };

export const deleteCookie = ( cookieName: string ) => {
    const allCookies: string = document.cookie;

    const cookiesObject: CookieObject = allCookies.split(";").reduce((acc, cookie) => {
        const [name, value]: string[] = cookie.trim().split("=");
        if (name) {
            acc[name]= value || "";
        }
        return acc;
    }, {} as CookieObject);

    if (cookieName in cookiesObject) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
};
